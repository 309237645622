<template>
  <div :class="$style.navigate">
    <button
      type="button"
      v-for="(item, key) in items"
      :key="key"
      @click=";(state.activeItem = key), $emit('change', item.value)"
      :class="[
        $style.navigateItem,
        { navigateItemActive: state.activeItem === key },
      ]"
    >
      <label class="text-xs ml-2 text-gray-500" v-if="item.label">
        {{ item.label }}
      </label>
    </button>
  </div>
</template>
<script>
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  setup() {
    const state = reactive({
      activeItem: 0,
    })

    return {
      state,
    }
  },
})
</script>

<style module>
.navigate {
  @apply bg-gray-100 flex w-full border-b-2 sticky top-0;
}
.navigateItem {
  @apply pt-3 pb-3 border-b-4 w-full border-transparent hover:border-gray-800 focus:outline-none;
}
</style>

<style>
.navigateItemActive {
  @apply border-gray-800 !important;
}
</style>
