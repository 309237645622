<template>
  <div class="flex w-full p-0 bg-gray-100" :class="$style.navigate">
    <button
      type="button"
      v-for="(item, key) in menu"
      :key="key"
      class="flex justify-center items-center h-8 w-full"
      @click=";(state.activeItem = key), $emit('change', item.value)"
      :class="[
        $style.navigateItem,
        { navigateItemActive: state.activeItem === key },
      ]"
    >
      <label class="text-xs text-gray-500" v-if="item.label">
        {{ item.label }}
      </label>
    </button>
  </div>
</template>
<script>
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  props: {
    menu: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  setup() {
    const state = reactive({
      activeItem: 0,
    })

    return {
      state,
    }
  },
})
</script>

<style module>
.navigateItem {
  @apply pt-3 pb-3 hover:bg-white focus:outline-none;
}
</style>

<style>
.navigateItemActive {
  @apply bg-white !important;
}
</style>
