import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Socketio from './plugins/socket'
import './index.css'

// InputField
import CButton from '@/components/InputField/CButton.vue'
import TextField from '@/components/InputField/TextField.vue'
import SelectField from '@/components/InputField/SelectField.vue'

createApp(App)
  .use(store)
  .use(router)
  .use(Socketio, {
    connection: process.env.VUE_APP_IRIS_API_URL,
    options: {
      auth: {
        token: localStorage.getItem('access_token'),
      },
    },
  })
  .component('CButton', CButton)
  .component('TextField', TextField)
  .component('SelectField', SelectField)
  .mount('#app')
