import { HTTP } from '../services/axios'
// import { useStore } from 'vuex'

// const store = useStore()
const _baseUrl = process.env.VUE_APP_CHAT_API_URL
// const tempToken =
;('eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJleHAiOjE2MzM3Njk0NTgsImlhdCI6MTYzMTE3NzQ3NSwiYWlkIjoiQkowNVAiLCJiaWQiOlsiUHFteVAiXSwiYmNpZCI6WyJEQXAzRSJdLCJiZGlkIjpbIk1hWjJwWnp5MzUiXSwic2lkIjoid0U0cUQifQ.xvMfV5xZUUiodw1gZVaA4-84YCygrvm40rhqyZV90KZHdEf-Ck_1Un6e8I8aswKTkqlSVUF9MFHEP2PjYg4s2hqrXdf-0bl0Ze9cElA2eTeXyNlsUexZjdfGC20moW-HzVl4U1KX775042cTFYP_G0TLlPY_VJzn0kg5fEkRuYShJVShiq89cJ6euZJ1SDjitDUy-SJ3AKn_GQO-HJdlhFhf1H2dxVfWCbfKjPna2zRY1cdDibdjHIu_M1NjM6tKSmPHBH2ocohMTzR4vER7IrT59b5KpSQQLPaGDKZgbPenxNT4MpZ41hcVFtxroNXYaUJqA4s-DdCqHACQqlfZJZRfatP5NsOPLsv8JTEWsGYU-HhXGNsSalHymE_64to8GSS7CBKFbACTDH0YdxZSRD6sggs1kw_QFoiM7gV-qBPnj1tni2Qi6IErrQMGHpP5t4NL_4aT0dsuVptyCaAGZ4Jv_ImgSF-xEHzNuymxcwtLAnYIuFkcn_9Mo6bW9SIkybYJ2hgqksveNYhm1s4XnccCcJ_IiLZ39Y07bEN8xIkeT_HoZ7lpu-oSz--c9FmYBI2X3Wg2rNEnHazlOpjfLqpJ2TLfoVcsbiSVhnVzh6gnitMjWiUlDPSZVANV5Mpnm73uMNPNSfZBceJz6LImKv4Ti-AH9IaYj1WeyP0e83c')

const _send = async (
  method = 'GET',
  url,
  params = null,
  data = null,
  headers
) => {
  try {
    const response = await HTTP({
      // headers: {
      //   Authorization: store.getters['sso/accessToken']
      //     ? `Bearer ${store.getters['sso/accessToken']}`
      //     : null,
      //   ...headers,
      // },
      headers: {
        Authorization: localStorage.getItem('access_token')
          ? `Bearer ${localStorage.getItem('access_token')}`
          : null,
        ...headers,
      },
      // headers: {
      //   Authorization: tempToken ? `Bearer ${tempToken}` : null,
      //   ...headers,
      // },
      method,
      url: _baseUrl + url,
      params,
      data,
    })
    return response.data
  } catch (error) {
    const data = error.response
    if (data) {
      if (data.code === 401) {
        localStorage.removeItem('tokenSetting')
      }
      if (data.code === 422) {
        if (data) {
          return data
        }
      } else if (data.code === 403) {
        // Logout Otomatis
        localStorage.removeItem('vuex')
        localStorage.removeItem('isSeller')
        localStorage.removeItem('userData')
        localStorage.removeItem('token')
        localStorage.removeItem('tokenSetting')
        localStorage.removeItem('access_token')
        // window.location.href = '/'
      } else if (data.code === 201 || data.code === 202) {
        return data
      }
    }
    return false
  }
}

const apiTako = {
  getAccounts: async (id, params) => {
    return await _send('GET', '/v0.0.0/accounts/profiles', params)
  },
  getChats: async (params) => {
    return await _send('GET', '/v0.0.0/chats', params)
  },
  getRooms: async (params) => {
    return await _send('GET', '/v0.0.0/rooms', params)
  },
  makeRooms: async (params, data) => {
    return await _send('POST', '/v0.0.0/rooms/init', params, data)
  },
}

export default apiTako
