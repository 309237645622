<template>
  <button
    :type="nativeType"
    v-bind="$attrs"
    :class="['focus:outline-none', btnClass]"
  >
    <i :class="['fas', iconLeft]" v-if="iconLeft"></i>
    <slot></slot>
    <i :class="['fas', iconRight]" v-if="iconRight"></i>
  </button>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    type: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'primary', 'text'].includes(value)
      },
    },
    size: {
      type: String,
      default: 'default',
      validator(value) {
        return ['small', 'default', 'large'].includes(value)
      },
    },
    rounded: {
      type: String,
      default: 'default',
      validator(value) {
        return ['small', 'default', 'large'].includes(value)
      },
    },
    nativeType: {
      type: String,
      default: 'button',
    },
    iconLeft: {
      type: String, //fontawesome
      default: null,
    },
    iconRight: {
      type: String, //fontawesome
      default: null,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const btnClass = computed(() => {
      return {
        border: props.outlined,
        'w-full': props.expanded,
        'text-gray-700': props.type === 'text',
        'bg-gray-700 text-white hover:opacity-80': props.type === 'primary',
        rounded: props.rounded === 'default',
        'rounded-full': props.rounded === 'full',
        'p-1.5 text-xs': props.size === 'small',
        'p-2 text-sm': props.size === 'default',
        'p-2.5': props.size === 'large',
      }
    })

    return {
      btnClass,
    }
  },
}
</script>
