<template>
  <div class="bg-gray-100 p-4" style="height: 52px">
    <div class="flex items-center">
      <i class="far fa-exclamation-triangle"></i>
      <p class="text-xs text-gray-400 ml-3">
        Please be careful and aware of communication or transaction from outside
        Indie Marketfest platform.
      </p>
    </div>
  </div>
</template>
<script>
export default {}
</script>
