import { render } from "./Navigation.vue?vue&type=template&id=656716f0"
import script from "./Navigation.vue?vue&type=script&lang=js"
export * from "./Navigation.vue?vue&type=script&lang=js"

const cssModules = script.__cssModules = {}
import style0 from "./Navigation.vue?vue&type=style&index=0&id=656716f0&module=true&lang=css"
cssModules["$style"] = style0
import "./Navigation.vue?vue&type=style&index=1&id=656716f0&lang=css"
script.render = render

export default script