import axios from 'axios'

const state = {
  accessToken: localStorage.getItem('access_token') || null,
}

const getters = {
  accessToken(state) {
    return state.accessToken
  },
}

const mutations = {
  SET_ACCESS_TOKEN(state, tokens) {
    state.accessToken = tokens.sso
    localStorage.setItem('access_token', tokens.sso)
    localStorage.setItem('tako_token', tokens.tako)
  },
  DELETE_ACCESS_TOKEN(state) {
    state.accessToken = null
  },
}

const actions = {
  // clear action
  destroyToken(ctx) {
    localStorage.removeItem('access_token')
    ctx.commit('DELETE_ACCESS_TOKEN')
  },

  //auth action
  retrieveSsoToken(ctx, params) {
    const tako_token = params.token
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.VUE_APP_SSO_API_URL + '/v0.0/auth/chamo',
        method: 'POST',
        data: params.data,
        headers: {
          Authorization: 'Basic ' + params.token,
        },
      })
        .then((resp) => {
          if (resp.data.code === 200) {
            const token = resp.data.data.token
            const tokens = {
              tako: tako_token,
              sso: token,
            }
            ctx.commit('SET_ACCESS_TOKEN', tokens)
          }
          resolve(resp)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
