import dayjs from 'dayjs'

export const useHelpers = () => {
  const formatDate = (unix, format) => {
    // Convert to Regular Date Format
    const d = new Date(unix * 1000)
    return dayjs(d).format(format ? format : 'DD-MM-YYYY')
  }

  return {
    formatDate,
  }
}
