<template>
  <input
    :type="type"
    v-bind="$attrs"
    :class="[$style.textField, customClass]"
    v-model="innerValue"
  />
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    type: {
      type: String,
      default: 'text',
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    size: {
      type: String,
      default: 'default',
      validator(value) {
        return ['small', 'default', 'large'].includes(value)
      },
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const innerValue = computed({
      get: () => props.modelValue,
      set: (newValue) => emit('input', newValue),
    })

    const customClass = computed(() => {
      return {
        'w-full': props.expanded,
        'p-1 pr-2 pl-2 text-xs': props.size === 'small',
        'pt-1 pb-1.5 pr-3 pl-3 text-sm': props.size === 'default',
        'p-2.5': props.size === 'large',
      }
    })
    return {
      innerValue,
      customClass,
    }
  },
}
</script>

<style module>
.textField {
  @apply border w-full rounded-md focus:outline-none focus:border-gray-500 text-gray-500;
}
</style>
