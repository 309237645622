import { render } from "./List.vue?vue&type=template&id=5b76d25a"
import script from "./List.vue?vue&type=script&lang=js"
export * from "./List.vue?vue&type=script&lang=js"

const cssModules = script.__cssModules = {}
import style0 from "./List.vue?vue&type=style&index=0&id=5b76d25a&module=true&lang=css"
cssModules["$style"] = style0
import "./List.vue?vue&type=style&index=1&id=5b76d25a&lang=css"
script.render = render

export default script