import axios from 'axios'
// import Router from 'vue-router'

const HTTP = axios.create({
  headers: {},
})

HTTP.interceptors.response.use(
  (response) => {
    // success interceptor
    const data = response.data

    if (data.code === 200 || data.code === 404) {
      return response
    } else {
      // auto logout
      // eslint-disable-next-line no-lonely-if
      if (data && data.code === 403) {
        if (data.message && data.message === 'Invalid Login Data') {
          localStorage.removeItem('vuex')
          localStorage.removeItem('userData')
          localStorage.removeItem('isSeller')
          localStorage.removeItem('token')
          localStorage.removeItem('access_token')
          // push only if not in login page
          // console.log(Router)
          // if (window.location.pathname !== '/') {
          // Router.push('/')
          // }
        }
      }
    }

    const error = {}
    error.response = response
    return Promise.reject(error)
  },
  (error) => {
    // error interceptor
    const data = error.response.data

    // auto logout
    if (data && data.code === 403) {
      if (data.message && data.message === 'Invalid Login Data') {
        localStorage.removeItem('vuex')
        localStorage.removeItem('userData')
        localStorage.removeItem('isSeller')
        localStorage.removeItem('token')
        localStorage.removeItem('tokenSso')

        // push only if not in login page
        // if (Router.currentRoute.fullPath !== '/') {
        //   Router.push('/')
        // }
      }
    }
    return Promise.reject(error)
  }
)

export { HTTP }
