<template>
  <div class="relative">
    <div class="absolute bottom-1 left-3">
      <div class="flex">
        <a
          href="#"
          :class="$style.templateItem"
          v-for="(item, key) in chatTemplates"
          :key="key"
          @click="$emit('change', item)"
        >
          {{ item }}
        </a>
        <a href="#" :class="$style.templateItem">
          <i class="far fa-cog"></i>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  setup() {
    const state = reactive({
      chatTemplates: ['Thank you for shopping!', 'Your order will be pack'],
    })
    return {
      ...toRefs(state),
    }
  },
})
</script>

<style module>
.templateItem {
  @apply border border-gray-500 p-2 pr-3 pl-3 rounded-3xl mr-3 text-xs bg-white;
}
</style>
