import { HTTP } from '../services/axios'
// import { useStore } from 'vuex'

// const store = useStore()
const _baseUrl = process.env.VUE_APP_TAKO_API_URL

const _send = async (
  method = 'GET',
  url,
  params = null,
  data = null,
  headers
) => {
  try {
    const response = await HTTP({
      // headers: {
      //   Authorization: store.getters['sso/accessToken']
      //     ? `Bearer ${store.getters['sso/accessToken']}`
      //     : null,
      //   ...headers,
      // },
      headers: {
        Authorization: localStorage.getItem('tako_token')
          ? `Bearer ${localStorage.getItem('tako_token')}`
          : null,
        ...headers,
      },
      method,
      url: _baseUrl + url,
      params,
      data,
    })
    return response.data
  } catch (error) {
    const data = error.response
    if (data) {
      if (data.code === 401) {
        localStorage.removeItem('tokenSetting')
      }
      if (data.code === 422) {
        if (data) {
          return data
        }
      } else if (data.code === 403) {
        // Logout Otomatis
        localStorage.removeItem('vuex')
        localStorage.removeItem('isSeller')
        localStorage.removeItem('userData')
        localStorage.removeItem('token')
        localStorage.removeItem('tokenSetting')
        localStorage.removeItem('access_token')
        // window.location.href = '/'
      } else if (data.code === 201 || data.code === 202) {
        return data
      }
    }
    return false
  }
}

const apiTako = {
  getProducts: async (params) => {
    return await _send('GET', '/v0.0.0/products', params)
  },
  getProductDetail: async (id, params) => {
    return await _send('GET', '/v0.0.0/products/' + id + '/update', params)
  },
  getPromotions: async (params) => {
    return await _send('GET', '/v0.0.0/promotions', params)
  },
  getPromotionDetail: async (id, params) => {
    return await _send('GET', '/v0.0.0/promotions/' + id, params)
  },
}

export default apiTako
