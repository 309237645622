import { createStore } from 'vuex'
import sso from './modules/sso'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    sso,
  },
})
