<template>
  <div class="side-menu border-l">
    <Navigation
      :items="navigations"
      @change="($event) => (navAction = $event)"
    />

    <!-- Order Menu -->
    <div v-if="navAction === 1">
      <SubNavigation
        :menu="orderMenu"
        @change="($event) => (activeOrderMenu = $event)"
      />
    </div>

    <!-- Product Menu -->
    <div v-if="navAction === 2">
      <SubNavigation
        :menu="productMenu"
        @change="($event) => (activeProductMenu = $event)"
      />
    </div>

    <!-- Promotion Menu -->
    <div v-if="navAction === 3">
      <SubNavigation
        :menu="promotionMenu"
        @change="($event) => (activePromotionMenu = $event)"
      />
    </div>

    <!-- Filter Search -->
    <div class="sidebarFilter" v-if="navAction !== 1">
      <div class="col-span-2">
        <TextField v-model="product.search" size="small" placeholder="Search" />
      </div>
      <div class="col-span-1">
        <SelectField
          v-model="product.sort"
          size="small"
          expanded
          placeholder="Select"
        >
          <option value="1">Newest</option>
          <option value="2">Oldest</option>
        </SelectField>
      </div>
    </div>

    <div
      class="overflow-y-scroll bg-gray-100"
      :class="navAction === 1 ? 'sideMenuHeight' : 'sideMenuHeight2'"
    >
      <div class="order-list" v-if="navAction === 1">
        <Orders v-for="item in 6" :sub-menu="activeOrderMenu" :key="item" />
      </div>
      <div class="product-list" v-else-if="navAction === 2">
        <Products v-for="(item, key) in products" :key="key" :payload="item" />
      </div>
      <div class="promotion-list" v-else-if="navAction === 3">
        <Promotions
          v-for="(item, key) in promotions"
          :key="key"
          :payload="item"
        />
      </div>
    </div>

    <div class="grid grid-cols-4 m-3 border-top">
      <div class="col-span-3">
        <p class="text-sm">(0/0) Items Selected</p>
      </div>
      <div class="col-span-1">
        <CButton type="primary" expanded size="small"> Send </CButton>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, watch, onMounted } from 'vue'
import apiTako from '../services/apiTako'
// import dayjs from 'dayjs'
import Navigation from './SideMenu/Navigation.vue'
import SubNavigation from './SideMenu/SubNavigation.vue'
import Orders from './SideMenu/Orders.vue'
import Products from './SideMenu/Products.vue'
import Promotions from './SideMenu/Promotions.vue'

export default defineComponent({
  components: {
    Navigation,
    SubNavigation,
    Orders,
    Products,
    Promotions,
  },
  setup() {
    const state = reactive({
      navAction: 1,
      activeOrderMenu: 1,
      activePromotionMenu: 5,
      navigations: [
        { value: 1, label: 'Orders', icon: 'fa-file-alt' },
        { value: 2, label: 'Products', icon: 'fa-tags' },
        { value: 3, label: 'Promo', icon: 'fa-bullhorn' },
      ],
      orderMenu: [
        { value: 1, label: 'All Order' },
        { value: 2, label: 'Processing' },
        { value: 3, label: 'Delivered' },
        { value: 4, label: 'Successfull' },
        { value: 5, label: 'Returned' },
      ],
      productMenu: [
        { value: 1, label: 'All Product' },
        { value: 2, label: 'Recommended' },
      ],
      promotionMenu: [
        { value: 5, label: 'Ongoing' },
        { value: 1, label: 'Upcoming' },
      ],
      product: {
        sort: 1,
        search: '',
      },
    })
    const products = ref([])
    const promotions = ref([])
    let pagination = reactive({})
    const productParams = reactive({
      business_id: null,
      limit: 10,
      page: 1,
      sort: 2,
      ownership_status: null,
      search: '',
      time_range: null,
      category_ids: null,
      outputs: [
        'model',
        'status',
        'style_data',
        'status_slug',
        'time_created',
        'is_reseller_product',
        'is_service',
        'time_modified',
        'preferred_gender',
        'brand_data',
        'image_datas',
        'wholesale_datas',
        'category_datas',
        'family_datas',
        'family_datas.stock_type',
        'family_datas.variant_name',
        'family_datas.size_name',
        'family_datas.promotion_datas',
        'family_datas.sales',
        'family_datas.stock',
        'video_link',
      ].toString(),
    })

    const promotionParams = reactive({
      limit: 10,
      page: 1,
      sort: 2,
      business_id: null,
      is_official: null,
      search: null,
      status: 5,
      is_multi_brand: false,
      outputs: [
        'description',
        'time_created',
        'time_modified',
        'max_usage',
        'brand_datas',
        'brand_datas.product_count',
        'brand_datas.product_stock',
        'brand_datas.sales_qty',
        'brand_datas.sales_net',
        'channel_data',
      ].toString(),
    })

    watch(
      () => state.activePromotionMenu,
      (newValue) => {
        promotionParams.status = newValue
        fetchPromotions()
      }
    )

    async function fetchProducts(type) {
      // const params = { params }
      // if (productParams.value.brand_ids === 'All Brands') {
      //   // params.brand_ids = [params.brand_id].toString()
      //   delete productParams.value.brand_ids
      // }

      // if (
      //   productParams.value.search.length <= 3 ||
      //   productParams.value.search === null
      // ) {
      //   delete productParams.value.search
      // }

      // if (productParams.value.date) {
      //   productParams.value.transaction_start_time = dayjs(
      //     productParams.value.date[0]
      //   ).unix()
      //   productParams.value.transaction_end_time = dayjs(
      //     productParams.value.date[1]
      //   )
      //     .add(23, 'hours')
      //     .add(59, 'minutes')
      //     .add(59, 'seconds')
      //     .unix()
      //   delete productParams.value.date
      // }
      if (type === 'filter') {
        const response = await apiTako.getProducts(productParams)
        if (response.code === 200) {
          products.value = response.data
          pagination.value = response.pagination
        } else {
          products.value = []
          pagination.value = response.pagination
        }
      } else {
        const response = await apiTako.getProducts(productParams)
        if (response.code === 200) {
          // products.value.push(...response.data)
          products.value = response.data
          pagination.value = response.pagination
        } else {
          products.value = []
          pagination.value = response.pagination
        }
      }
    }

    async function fetchPromotions(type) {
      // if (
      //   promotionParams.value.search.length <= 3 ||
      //   promotionParams.value.search === null
      // ) {
      //   delete promotionParams.value.search
      // }

      // if (promotionParams.value.date) {
      //   promotionParams.value.transaction_start_time = dayjs(
      //     promotionParams.value.date[0]
      //   ).unix()
      //   promotionParams.value.transaction_end_time = dayjs(
      //     promotionParams.value.date[1]
      //   )
      //     .add(23, 'hours')
      //     .add(59, 'minutes')
      //     .add(59, 'seconds')
      //     .unix()
      //   delete promotionParams.value.date
      // }
      if (type === 'filter') {
        const response = await apiTako.getPromotions(promotionParams)
        if (response.code === 200) {
          promotions.value = response.data
          pagination.value = response.pagination
        } else {
          promotions.value = []
          pagination.value = response.pagination
        }
      } else {
        const response = await apiTako.getPromotions(promotionParams)
        if (response.code === 200) {
          // promotions.value.push(...response.data)
          promotions.value = response.data
          console.log('sssss', response.data)
          pagination.value = response.pagination
        } else {
          promotions.value = []
          pagination.value = response.pagination
        }
      }
    }

    onMounted(() => {
      fetchProducts()
      fetchPromotions()
    })

    return {
      ...toRefs(state),
      fetchPromotions,
      products,
      promotions,
    }
  },
})
</script>

<style>
.sidebarFilter {
  @apply bg-white p-2 grid grid-cols-3 gap-1 sticky top-0 border-b;
}
.sideMenuHeight {
  height: calc(100vh - 190px);
}
.sideMenuHeight2 {
  height: calc(100vh - 237px);
}
</style>
