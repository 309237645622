<template>
  <div class="flex border-b mb-1 shadow bg-white">
    <div class="px-2 flex items-center justify-items-center border-r">
      <input type="checkbox" name="" id="" />
    </div>
    <div class="w-full">
      <div class="border-b text-xs p-2">
        <div class="flex items-center">
          <div>
            <img
              v-if="payload.image_datas"
              :src="payload.image_datas[0].image"
              width="40"
            />
            <img v-else src="https://via.placeholder.com/150" width="40" />
          </div>
          <div class="product-detail text-xs ml-3 flex-1">
            <p class="text-blue-400">{{ payload.brand_data.name }}</p>
            <p class="font-light">{{ payload.product_name }}</p>
            <p class="text-yellow-500">
              Rp. {{ payload.family_datas[0].price }}
            </p>
          </div>
        </div>
      </div>
      <div class="border-b text-xs font-light flex p-2">
        <p class="border-r mr-3 pr-3 text-gray-400">
          Available {{ payload.family_datas[0].stock }} pcs
        </p>
        <p>Sold {{ payload.family_datas[0].sales }} pcs</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
export default defineComponent({
  props: {
    payload: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  setup() {
    onMounted(() => {})
  },
})
</script>
