<template>
  <div class="order border-b mb-2 shadow-md bg-white">
    <!-- <pre>{{ payload }}</pre> -->
    <div class="border-b text-xs p-2 font-light">
      <div class="flex flex-col">
        <div class="flex justify-between mb-1">
          <p class="font-bold">{{ payload.title }}</p>
          <a href="#" class="text-blue-400">View Detail</a>
        </div>
        <p class="text-gray-400">{{ payload.description }}</p>
      </div>
      <div class="flex justify-between mt-3">
        <p>Promotion Period</p>
        <p v-html="getDuration(payload.time_start, payload.time_end)"></p>
      </div>
      <div class="flex justify-between text-gray-400 text-xs">
        <div class="flex flex-col">
          <p>Start</p>
          <p>End</p>
        </div>
        <div class="flex flex-col text-right">
          <p v-html="formatDate(payload.time_start)"></p>
          <p v-html="formatDate(payload.time_end)"></p>
        </div>
      </div>

      <div class="brand-participant">
        <p class="mt-3">
          Brand Participant ( {{ payload.brand_datas.length }} )
        </p>
        <div
          class="flex justify-between text-gray-400 text-xs mb-1"
          v-for="(item, key) in payload.brand_datas"
          :key="key"
        >
          <p>{{ item.name }}</p>
          <p>{{ item.product_count }} products</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import moment from 'moment'

export default defineComponent({
  props: {
    payload: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  setup() {
    function formatDate(value) {
      const unix = value * 1000
      const date = new Date(unix)
      return moment(date).format('DD/MM/YYYY HH:mm:ss')
    }

    function getDuration(startDate, endDate) {
      const start = new Date(startDate * 1000)
      const end = new Date(endDate * 1000)
      const dur = moment(end).diff(moment(start), 'hours')
      return `${dur} Hours left`
    }

    onMounted(() => {})

    return {
      formatDate,
      getDuration,
    }
  },
})
</script>
