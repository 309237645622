<template>
  <div class="grid grid-cols-3 border-b p-3" style="height: 52px">
    <div>
      <div class="flex">
        <div class="mr-3">
          <img
            src="https://via.placeholder.com/150"
            class="w-8 h-8 rounded-full mx-auto"
          />
        </div>
        <div>
          <p class="text-xs">Sophie Graves</p>
          <p class="text-xs text-gray-500">Location name</p>
        </div>
      </div>
    </div>
    <div>
      <p class="text-xs">
        <i class="fas fa-check-square text-green-500 mr-4"></i>
        1.230.000 <span class="text-gray-400">(1 order)</span>
      </p>
      <p class="text-xs">
        <i class="fas fa-window-close text-gray-500 mr-3.5"></i>
        0 <span class="text-gray-400">(0 order)</span>
      </p>
    </div>
    <div class="text-right">
      <div class="flex justify-end">
        <div class="text-xs">
          <p>Chat Type</p>
          <p class="text-gray-500">Order Chat</p>
        </div>
        <div class="sidebar">
          <a
            href="#"
            @click="showSidebar = !showSidebar"
            class="ml-4 text-md block p-1 pr-2 pl-2"
            :class="{ active: showSidebar }"
          >
            <i class="fal fa-columns"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, watch } from 'vue'
export default defineComponent({
  setup(_, { emit }) {
    const state = reactive({
      showSidebar: false,
    })

    watch(
      () => state.showSidebar,
      (value) => {
        emit('change', value)
      }
    )

    return {
      ...toRefs(state),
    }
  },
})
</script>

<style lang="scss">
.active {
  @apply bg-gray-200;
}
</style>
