<template>
  <select
    v-bind="$attrs"
    :class="['selectField', customClass]"
    v-model="innerValue"
  >
    <slot></slot>
  </select>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },
    plain: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'default',
      validator(value) {
        return ['small', 'default', 'large'].includes(value)
      },
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const innerValue = computed({
      get: () => props.modelValue,
      set: (newValue) => emit('input', newValue),
    })
    const customClass = computed(() => {
      return {
        'w-full': props.expanded,
        'focus:border-gray-500 border': props.plain,
        'border-0 bg-transparent': !props.plain,
        'p-1.5 text-xs': props.size === 'small',
        'p-2 text-sm': props.size === 'default',
        'p-2.5': props.size === 'large',
      }
    })
    return {
      innerValue,
      customClass,
    }
  },
}
</script>

<style>
.selectField {
  @apply rounded-md focus:outline-none text-gray-500;
}
</style>
