<template>
  <div class="order border-b mb-1 shadow bg-white">
    <div class="border-b text-xs p-2">
      <div
        v-for="(item, key) in headline"
        :key="key"
        class="flex justify-between"
      >
        <p v-if="item.value === subMenu" class="font-bold">
          {{ item.label }}
        </p>
        <p v-if="item.value === subMenu" class="text-gray-400">
          23 July 2019 12:19
        </p>
      </div>
      <p class="text-blue-400 mt-1 mb-1">INV/VXII/234/AH34Q08FDKIE</p>
      <a href="#" class="text-blue-400">View Detail Order</a>
      <div class="flex mt-3">
        <div>
          <img src="https://via.placeholder.com/150" width="40" />
        </div>
        <div class="product-detail text-xs ml-3 flex-1">
          <p class="text-blue-400">Woman Classic Handbag</p>
          <p class="font-light">Maroon - Medium</p>
          <p class="text-yellow-500">932.105</p>
        </div>
        <p>x1</p>
      </div>
    </div>
    <div class="border-b text-xs p-2">
      <div class="flex justify-between font-light">
        <p>Channel</p>
        <div class="text-right">
          <p>Social Media</p>
          <p>Whatsapp</p>
        </div>
      </div>
      <div class="flex justify-between font-light mt-2">
        <p>Payment Amount</p>
        <div class="text-right">
          <p class="text-yellow-500">Rp. 1.230.000</p>
          <p>BCA (Bank Transfer)</p>
        </div>
      </div>
      <div class="flex justify-between font-light mt-2">
        <p>Delivery Service</p>
        <p>JNE OKE</p>
      </div>
    </div>
    <div class="text-xs p-2">
      <div class="grid grid-cols-4 font-light">
        <div class="col-span-3">
          <p>Give Feedback</p>
          <p class="text-xs text-gray-400">
            Batas waktu beri nilai sampai 27 Mar 2020, 15:28
          </p>
        </div>
        <p class="text-blue-400 text-right">Rate Now</p>
      </div>
      <div class="flex justify-between font-light mt-2">
        <p>Seller Note</p>
        <a href="#" class="text-blue-400">
          <i class="fas fa-plus"></i>
          Add Note
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  props: {
    subMenu: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup() {
    const headline = reactive([
      {
        value: 1,
        label: 'All Order',
      },
      {
        value: 2,
        label: 'Processing',
      },
      {
        value: 3,
        label: 'Delivered',
      },
      {
        value: 4,
        label: 'Successfull',
      },
      {
        value: 5,
        label: 'Returned',
      },
    ])

    return {
      headline,
    }
  },
})
</script>
