<template>
  <div class="h-full" :class="$style.list">
    <!-- Indiepos -->
    <div>
      <ChatContentNav
        :items="contentNav"
        @change="($event) => (navAction = $event)"
      />
    </div>

    <div class="flex" style="height: 50px">
      <!-- <SelectField
        v-model="chatAs"
        class="flex bg-white border-b w-full items-center px-4"
        style="appearance: none"
      >
        <option v-for="(item, key) in listUser" :key="key" :value="item.value">
          <img
            src="https://via.placeholder.com/150"
            class="w-8 h-8 rounded-full mr-3"
          />
          <div class="flex flex-col">
            <p class="text-sm">{{ item.label }}</p>
          </div>
        </option>
      </SelectField> -->
      <div class="relative w-full">
        <div class="flex items-center h-full">
          <button
            v-if="!selectedUser.ref_id"
            type="button"
            class="
              flex
              items-center
              justify-between
              w-full
              h-full
              bg-white
              pl-4
              pr-7
              text-left
              cursor-pointer
              focus:outline-none
              sm:text-sm
            "
            aria-haspopup="listbox"
            aria-expanded="true"
            aria-labelledby="listbox-label"
            @click="isOpen = !isOpen"
          >
            <div class="flex items-center">
              <div class="flex flex-col">
                <span class="block truncate"> Select Account </span>
              </div>
            </div>
          </button>
          <button
            v-else
            type="button"
            class="
              flex
              items-center
              justify-between
              w-full
              h-full
              bg-white
              pl-4
              pr-8
              text-left
              cursor-pointer
              focus:outline-none
              sm:text-sm
            "
            aria-haspopup="listbox"
            aria-expanded="true"
            aria-labelledby="listbox-label"
            @click="isOpen = !isOpen"
          >
            <div class="flex items-center">
              <img
                :src="selectedUser.image"
                class="mr-3 flex-shrink-0 h-8 w-8 rounded-full"
              />
              <div class="flex flex-col">
                <span class="block truncate">
                  {{ selectedUser.name }}
                </span>
                <span class="block truncate text-xs">
                  {{ selectedUser.subtitle }}
                </span>
              </div>
            </div>
            <div
              class="
                flex
                justify-center
                items-center
                h-5
                w-10
                bg-indigo-500
                text-white text-xs
                p-1
                rounded-lg
              "
            >
              <span>
                {{ selectedUser.type === 2 ? 'Staff' : 'Brand' }}
              </span>
            </div>
          </button>
        </div>
        <ul
          v-if="isOpen"
          class="
            absolute
            z-10
            w-full
            bg-white
            shadow-lg
            max-h-72
            rounded-md
            py-1
            text-base
            ring-1 ring-black ring-opacity-5
            overflow-auto
            focus:outline-none
            sm:text-sm
          "
          tabindex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-option-3"
        >
          <li
            v-for="(item, key) in userList"
            :key="key"
            class="
              text-gray-900
              cursor-pointer
              select-none
              flex
              justify-between
              items-center
              py-2
              px-4
              hover:bg-gray-200
            "
            id="listbox-option-0"
            role="option"
            @click="handleSwitchUser(item)"
          >
            <div class="flex items-center">
              <img
                :src="item.image"
                class="flex-shrink-0 h-6 w-6 rounded-full"
              />
              <div class="flex flex-col">
                <span class="font-normal ml-3 block truncate">
                  {{ item.name }}
                </span>
                <span class="font-normal ml-3 block truncate text-xs">
                  {{ item.subtitle }}
                </span>
              </div>
            </div>
            <div
              class="
                flex
                justify-center
                items-center
                h-5
                w-10
                bg-indigo-500
                text-white text-xs
                p-1
                rounded-lg
              "
            >
              <span> {{ item.type === 2 ? 'Staff' : 'Brand' }} </span>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div
      class="flex items-center px-4 border-b-2 border-t"
      style="height: 52px"
    >
      <div class="flex w-full justify-between items-center">
        <div class="flex-auto">
          <p class="text-sm">Chat List (123 chats)</p>
        </div>
        <div class="flex-auto text-right">
          <SelectField v-model="sort" size="small" placeholder="Select">
            <option value="1">Newest</option>
            <option value="2">Oldest</option>
          </SelectField>
        </div>
      </div>
    </div>
    <div class="p-2 bg-gray-100">
      <input
        class="
          w-full
          py-2
          px-3
          text-sm
          outline-none
          rounded-sm
          focus:ring-2 focus:ring-gray-400
        "
        v-model="search"
        size="small"
        placeholder="Search Chat"
      />
    </div>

    <div :class="$style.chatList" style="height: calc(100vh - 210px)">
      <a
        :class="[$style.listItem, { listItemActive: chatActive === key }]"
        v-for="(item, key) in dummyList"
        @click="handleSelectChat(item, key)"
        :key="key"
      >
        <div :class="$style.listItemWrapper">
          <div>
            <img
              :src="item.chat_data.sender_data.user_data.image"
              class="w-9 h-9 rounded-full mx-auto"
            />
          </div>
          <div class="col-span-5">
            <div class="grid grid-cols-2 gap-2 mb-1">
              <div>
                <p :class="$style.textUsername">
                  {{ item.chat_data.sender_data.user_data.fullname }}
                  <i class="fal fa-check-double"></i>
                </p>
              </div>
              <div class="text-right">
                <p :class="$style.textPreviewMessage">
                  {{
                    formatDate(item.chat_data.time_sent, 'DD MMM YYYY hh:mm')
                  }}
                </p>
              </div>
            </div>
            <div class="flex justify-between">
              <p :class="$style.textPreviewMessage" style="max-width: 15rem">
                {{ item.chat_data.body }}
              </p>

              <div
                class="
                  flex
                  items-center
                  justify-center
                  h-5
                  w-5
                  rounded-full
                  bg-yellow-400
                  text-xs
                "
              >
                5
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, watch, onMounted } from 'vue'
import { useHelpers } from '@/helpers'
import apiChamo from '../services/apiChamo'
import ChatContentNav from './ChatMenu/ChatContentNavigation.vue'

export default defineComponent({
  components: {
    ChatContentNav,
  },
  props: {
    userList: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const isOpen = ref(false)
    const { formatDate } = useHelpers()
    // let chatContent = reactive({})
    let selectedUser = reactive({
      ref_id: null,
      image: null,
      name: null,
      subtitle: null,
      type: null,
    })
    const chatList = ref([])
    const state = reactive({
      sort: '1',
      search: '',
      indieChat: 1,
      chatActive: 0,
      contentNav: [
        { value: 1, label: 'ALL CHAT' },
        { value: 2, label: 'INDIE CARE' },
      ],
    })
    const dummyList = ref([
      {
        room_type: 2,
        detail_data: {
          user_data: {
            fullname: 'Mochammad Zachri',
            username: 'mochammad4708',
            email: 'mochammadzachri@gmail.com',
            phone: '6281910222989',
            image:
              'https://lh3.googleusercontent.com/-fMvP1eCy90s/AAAAAAAAAAI/AAAAAAAAAys/yQxU7PrFUFg/s50/photo.jpg',
            time_created: 1552282870,
          },
          branch_data: {
            slug: 'mabacco-mekar-agung-6340',
            name: 'Mabacco - Mekar Agung',
            phone_1: '6282182059722',
            phone_2: '6282182059722',
            description: 'Mabacco Mekar Agung',
            address: 'Jl Mekar Agung No.16',
            image: 'https://assets.olyuk.com/uploads/8lZngEG6a2.png',
            postcode: '40237',
            latitude: -6.9175639,
            longitude: 107.6093662,
            time_created: 1599030753,
            email: null,
          },
        },
        status: 1,
        time_created: 1616385116,
        time_modified: 1616385116,
        id: '6058183be51ba4920a297c09',
        chat_data: {
          room_id: '6058183be51ba4920a297c09',
          quoted_chat: null,
          type: 0,
          sender_data: {
            user_data: {
              fullname: 'Mochammad Zachri',
              username: 'mochammad4708',
              email: 'mochammadzachri@gmail.com',
              phone: '6281910222989',
              image:
                'https://lh3.googleusercontent.com/-fMvP1eCy90s/AAAAAAAAAAI/AAAAAAAAAys/yQxU7PrFUFg/s50/photo.jpg',
              time_created: 1552282870,
            },
          },
          body: 'Kak pesanan saya kapan siapnya? masa udah seminggu belum di proses juga sih . kamu jahat!',
          attachment_data: null,
          status: 2,
          time_created: 1616385116,
          time_sent: 1616385117,
          time_received: null,
          time_read: null,
          id: '605836dce51ba4920a29b1f9',
          is_me: false,
        },
      },
      {
        room_type: 2,
        detail_data: {
          user_data: {
            fullname: 'Yayang Kurnia',
            username: 'nyan21',
            email: 'mochammadzachri@gmail.com',
            phone: '6281910222989',
            image:
              'https://lh3.googleusercontent.com/-fMvP1eCy90s/AAAAAAAAAAI/AAAAAAAAAys/yQxU7PrFUFg/s50/photo.jpg',
            time_created: 1552282870,
          },
          branch_data: {
            slug: 'mabacco-mekar-agung-6340',
            name: 'Mabacco - Mekar Agung',
            phone_1: '6282182059722',
            phone_2: '6282182059722',
            description: 'Mabacco Mekar Agung',
            address: 'Jl Mekar Agung No.16',
            image: 'https://assets.olyuk.com/uploads/8lZngEG6a2.png',
            postcode: '40237',
            latitude: -6.9175639,
            longitude: 107.6093662,
            time_created: 1599030753,
            email: null,
          },
        },
        status: 1,
        time_created: 1616385116,
        time_modified: 1616385116,
        id: '6058183be51ba4920a297c09',
        chat_data: {
          room_id: '6058183be51ba4920a297c09',
          quoted_chat: null,
          type: 0,
          sender_data: {
            user_data: {
              fullname: 'Yayang Kurnia',
              username: 'nyan21',
              email: 'mochammadzachri@gmail.com',
              phone: '6281910222989',
              image:
                'https://lh3.googleusercontent.com/-fMvP1eCy90s/AAAAAAAAAAI/AAAAAAAAAys/yQxU7PrFUFg/s50/photo.jpg',
              time_created: 1552282870,
            },
          },
          body: 'Data dummy hanya untuk keperluan testing saja',
          attachment_data: null,
          status: 2,
          time_created: 1616385116,
          time_sent: 1616385117,
          time_received: null,
          time_read: null,
          id: '605836dce51ba4920a29b1f9',
          is_me: false,
        },
      },
    ])

    watch(
      () => selectedUser.ref_id,
      (newValue) => {
        console.log('switched', newValue)
        fetchChatList()
      }
    )

    async function fetchChatList() {
      const chatParams = {
        profile_id: selectedUser.ref_id,
        profile_type: selectedUser.type,
        limit: 10,
        sort: 1,
      }
      const response = await apiChamo.getRooms(chatParams)
      if (response.code === 200) {
        chatList.value = response.data
      } else {
        console.log('failed', response)
      }
    }

    onMounted(() => {})

    function handleSwitchUser(item) {
      isOpen.value = !isOpen.value
      selectedUser.ref_id = item.ref_id
      selectedUser.image = item.image
      selectedUser.name = item.name
      selectedUser.subtitle = item.subtitle
      selectedUser.type = item.type
    }

    function handleSelectChat(item, key) {
      state.chatActive = key
      console.log('item', item.id)
      emit('change', item)
    }

    return {
      isOpen,
      selectedUser,
      formatDate,
      chatList,
      dummyList,
      handleSwitchUser,
      handleSelectChat,
      ...toRefs(state),
    }
  },
})
</script>

<style module>
.list {
  @apply w-full border-r;
}

.textUsername {
  @apply text-xs truncate;
}

.textPreviewMessage {
  @apply text-xs text-gray-400 truncate;
}

.chatList {
  @apply overflow-y-scroll;
}

.listItemWrapper {
  @apply grid w-full grid-cols-6 gap-2 border-b border-gray-200 p-2.5 cursor-pointer select-none items-center;
}

.listItem {
  @apply flex text-gray-500 border-l-4 border-transparent hover:border-gray-700 hover:bg-blue-100;
}
</style>

<style>
.listItemActive {
  @apply border-l-4 border-gray-700 bg-blue-100;
}
</style>
