<template>
  <div class="content">
    <!-- chat alert -->
    <ChatAlert />

    <!-- chat wrapper -->
    <div :class="$style.messageWrapper">
      <div class="grid grid-cols-5">
        <div :class="showSidebar ? 'col-span-3' : 'col-span-5'">
          <!-- user info -->
          <UserInfo @change="showSidebar = $event" />

          <!-- chat content -->
          <div
            id="boxWrapper"
            class="wrapperHeightChat"
            :class="$style.chatContent"
          >
            <div
              v-for="(chat, key) in chats"
              :key="key"
              :class="{ 'direction-right': chat.type === 2 }"
            >
              <!-- chat time -->
              <p class="text-xs text-center text-gray-400 direction-left">
                {{ formatDate(chat.time_created, 'DD MMMM YYYY') }}
              </p>

              <!-- chat with product-detail -->
              <!-- <ChatProductDetail
                v-if="chat.product"
                :payload="chat.chat_data.body"
              /> -->

              <!-- message-wrapper -->
              <div
                :class="
                  chat.type === 2 ? $style.chatBSelf : $style.chatBPartner
                "
              >
                <div class="text-xs direction-left" v-html="chat.body"></div>
                <p class="text-xs mt-2 mb-0">
                  {{ formatDate(chat.time_sent, 'HH:mm') }}
                </p>
              </div>
            </div>
          </div>

          <!-- message template -->
          <MessagesTemplate @change="chatValue = $event" />

          <!-- chat action -->
          <div :class="$style.chatAction">
            <form @submit.prevent="postChat">
              <div class="grid grid-cols-12 items-center">
                <div class="col-span-1 text-center">
                  <a class="text-xl text-gray-400">
                    <i class="far fa-plus-circle"></i>
                  </a>
                </div>
                <div class="col-span-10">
                  <TextField placeholder="Type something" v-model="chatValue" />
                </div>
                <div class="col-span-1 text-center">
                  <button type="submit" class="text-xl focus:outline-none">
                    <i class="fas fa-paper-plane"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- sidebar menu -->
        <div v-if="showSidebar" class="col-span-2">
          <SideMenu />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import SideMenu from './SideMenu.vue'
import UserInfo from './ChatWidget/UserInfo.vue'
import ChatAlert from './ChatWidget/ChatAlert.vue'
// import ChatProductDetail from './ChatWidget/ChatProductDetail.vue'
import MessagesTemplate from './ChatWidget/MessagesTemplate.vue'
import { defineComponent, inject, onMounted, reactive, toRefs } from 'vue'
import { useHelpers } from '@/helpers'

export default defineComponent({
  components: {
    SideMenu,
    ChatAlert,
    UserInfo,
    // ChatProductDetail,
    MessagesTemplate,
  },
  props: {
    chatContent: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  setup() {
    const socket = inject('socket')
    const { formatDate } = useHelpers()
    const state = reactive({
      showSidebar: false,
      chatValue: '',
      chats: [
        {
          room_id: '6058183be51ba4920a297c09',
          quoted_chat: null,
          type: 0,
          sender_id: {
            user_id: 155228287062850,
          },
          sender_type: 0,
          sender_data: {
            user_data: {
              fullname: 'Mochammad Zachri',
              username: 'mochammad4708',
              email: 'mochammadzachri@gmail.com',
              phone: '6281910222989',
              image:
                'https://lh3.googleusercontent.com/-fMvP1eCy90s/AAAAAAAAAAI/AAAAAAAAAys/yQxU7PrFUFg/s50/photo.jpg',
              time_created: 1552282870,
            },
          },
          body: 'Kak pesanan saya kapan siapnya? masa udah seminggu belum di proses juga sih . kamu jahat!',
          attachment_data: null,
          status: 2,
          time_created: 1616385116,
          time_sent: 1616385117,
          time_received: null,
          time_read: null,
          id: '605836dce51ba4920a29b1f9',
          is_me: false,
        },
        {
          room_id: '6058183be51ba4920a297c09',
          quoted_chat: null,
          type: 2,
          sender_id: {
            user_id: 155228287062850,
          },
          sender_type: 1,
          sender_data: {
            user_data: {
              fullname: 'Yayang Kurnia',
              username: 'nyan21',
              email: 'mochammadzachri@gmail.com',
              phone: '6281910222989',
              image:
                'https://lh3.googleusercontent.com/-fMvP1eCy90s/AAAAAAAAAAI/AAAAAAAAAys/yQxU7PrFUFg/s50/photo.jpg',
              time_created: 1552282870,
            },
          },
          body: 'Ya ga tau atuh',
          attachment_data: null,
          status: 2,
          time_created: 1616385116,
          time_sent: 1616385117,
          time_received: null,
          time_read: null,
          id: '605836dce51ba4920a29b1f9',
          is_me: true,
        },
        {
          room_id: '6058183be51ba4920a297c09',
          quoted_chat: null,
          type: 0,
          sender_id: {
            user_id: 155228287062850,
          },
          sender_type: 0,
          sender_data: {
            user_data: {
              fullname: 'Mochammad Zachri',
              username: 'mochammad4708',
              email: 'mochammadzachri@gmail.com',
              phone: '6281910222989',
              image:
                'https://lh3.googleusercontent.com/-fMvP1eCy90s/AAAAAAAAAAI/AAAAAAAAAys/yQxU7PrFUFg/s50/photo.jpg',
              time_created: 1552282870,
            },
          },
          body: 'Hor kumahanya ieu mah nu dagang teh',
          attachment_data: null,
          status: 2,
          time_created: 1616385116,
          time_sent: 1616385117,
          time_received: null,
          time_read: null,
          id: '605836dce51ba4920a29b1f9',
          is_me: false,
        },
      ],
    })

    // function callSocket(socket) {
    //   const bar = ref('ssssss')
    //   socket.on('connection', (value) => {
    //     bar.value = value
    //   })

    //   return {
    //     bar,
    //   }
    // }

    function scrollToBottom() {
      const boxWrapper = document.querySelector('#boxWrapper')
      if (boxWrapper) {
        setTimeout(() => {
          boxWrapper.scrollTop = boxWrapper.scrollHeight
        }, 100)
      }
    }

    const postChat = async () => {
      if (state.chatValue) {
        state.chats.push({
          type: 2,
          body: state.chatValue,
          time_created: dayjs().unix(),
          time_sent: dayjs().unix(),
        })
        socket.emit('chat', {
          message: state.chats,
        })
        state.chatValue = ''
        scrollToBottom()
      }
    }

    function isShowDate(datetime, key) {
      return (
        key === 0 ||
        (key > 1 &&
          formatDate(datetime, 'DD MMM YYYY') !==
            formatDate(state.chats[key - 1].datetime, 'DD MMM YYYY'))
      )
    }

    onMounted(() => {
      // console.log('ck', props.chatContent)
      // callSocket(socket)
      // socket.on('connection', (socketConn) => {
      //   socketConn.onAny((eventName, ...args) => {
      //     console.table('event', eventName, 'args', args)
      //   })
      // })
      socket.emit('onChatJoin', localStorage.getItem('access_token'))
    })

    // onUnmounted(() => {
    //   socket
    // })

    return {
      postChat,
      formatDate,
      scrollToBottom,
      isShowDate,
      ...toRefs(state),
    }
  },
})
</script>

<style module>
.chatContent {
  @apply p-3 overflow-y-scroll;
}
.chatAction {
  @apply sticky bottom-0 border-t border-r p-2 bg-white;
}
.chatBGroup {
  @apply mt-3 mb-3 p-3 max-w-sm text-sm inline-block;
}
.chatBSelf {
  @apply chatBGroup rounded-xl rounded-br-none bg-gray-700 text-white;
}
.chatBPartner {
  @apply chatBGroup rounded-xl rounded-bl-none text-gray-700 bg-gray-200;
}
</style>

<style>
.wrapperHeightChat {
  height: calc(100vh - 155px);
}
</style>
