import * as io from 'socket.io-client'

export default {
  install: (app, { connection, options }) => {
    const socket = io(connection, options)
    app.config.globalProperties.$socket = socket

    app.provide('socket', socket)
    // socket.on('onChatJoin', () => {
    //   console.log('Join Da Chat')
    // })
    // console.log('ops', connection, options, socket.on)
  },
}
