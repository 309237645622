<template>
  <div class="chat-widget h-screen">
    <div class="grid grid-cols-7">
      <div class="col-span-2">
        <List :user-list="userList" @change="activeChat" />
      </div>
      <div class="col-span-5">
        <!-- <pre> {{ chatContent.value }} </pre> -->
        <Content :chat-content="chatContent.value" />
      </div>
    </div>
  </div>
</template>

<script>
import List from '@/components/List.vue'
import Content from '@/components/Content.vue'
import { ref, computed, onMounted, watch, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import apiChamo from '../services/apiChamo'

export default {
  components: {
    List,
    Content,
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const appsQuery = computed(() => route.query)
    const accessToken = computed(() => store.getters['sso/accessToken'])
    const userList = ref([])
    let chatContent = reactive({})
    // const paramsChat = reactive({
    //   profile_id: 'MaZ2pZzy35',
    //   profile_type: 2,
    //   limit: 10,
    //   room_id: '60e6cded61398e04a95b4bd4'
    // })
    // const data =

    watch(
      () => accessToken.value,
      (value) => {
        if (value) {
          fetchChamo()
        }
      }
    )

    async function fetchChamo() {
      const response = await apiChamo.getAccounts()
      if (response.code === 200) {
        userList.value = response.data
      } else {
        console.log('failed', response)
      }
    }

    function activeChat(event) {
      chatContent.value = event
    }

    onMounted(() => {
      const q = appsQuery.value
      if (q.apps_token) {
        store.dispatch('sso/retrieveSsoToken', {
          token: q.apps_token,
          data: {
            /* eslint-disable */
            ref_id: q.ref_id,
            user_id: q.user_id || null,
            staff_id: q.staff_id || null,
            brand_ids: q.brand_ids
              ? Array.isArray(q.brand_ids)
                ? q.brand_ids
                : [q.brand_ids]
              : [],
            branch_ids: q.branch_ids
              ? Array.isArray(q.branch_ids)
                ? q.branch_ids
                : [q.branch_ids]
              : [],
            business_ids: q.business_ids
              ? Array.isArray(q.business_ids)
                ? q.business_ids
                : [q.business_ids]
              : [],
          },
        })
      }
    })

    return {
      userList,
      activeChat,
      chatContent,
    }
  },
}
</script>
